import '../css/About.css';
import DecorativeBar from './DecorativeBar';
import { useTranslation } from 'react-i18next';
import globe from '../assets/globe.png'
import {useEffect, useState} from "react";

const About = () => {
    const {t} = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
      <section id="about" className="about">
        <span className='about-header'>{t("about.about")}</span>
          <div className="about-decorative-bar-title">
              <DecorativeBar/>
          </div>

        <div className="about-content">
            <span className='about-hint-span'>{t("about.about_hint")}</span>
            <img src={globe} alt="globe" className='globe-image'/>
        </div>
          {!isMobile && (<div className="about-decorative-bar">
              <DecorativeBar type={2}/>
          </div>)}

      </section>
    )
}

export default About;
