import '../css/Jobs.css';
import DecorativeBar from './DecorativeBar';
import { useTranslation } from 'react-i18next';
import React, {useState, useEffect} from 'react';
import { fetchPositions } from '../services/apiService';
import arrowDown from '../assets/circledown.png';
import arrowUp from '../assets/circleup.png';
import atlantic_jobs_logo from '../assets/crewlogo.png';
import crew_members from '../assets/crewmembers.png';

const Jobs = () => {
  const {t, i18n} = useTranslation();
  const [expanded, setExpanded] = useState(null);
  const [positions, setPositions] = useState([]);

    
  useEffect(() => {
    const language = i18n.language;
    fetchPositions(language)
        .then(data => setPositions(data))
        .catch(error => console.error('Error fetching positions: ' + error));
  }, [i18n.language]);

  const toggleExpanded = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  const smoothScroll = (id) => {
    let element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  return (
      <section className="jobs">
        <div className='job-header'>
          <span className='about-header'>Jobs</span>
            <DecorativeBar/>
            <div className="image-container">
              <div className='looking-for'>
                We are looking for
                <div className="crew-count">
                    <div className="crew-count-inner">
                        <div className="crew-count-inner-text">
                            {positions ? positions.length : 0}
                        </div>
                    </div>
                </div>
              <div style={{position:"relative"}}>
                <span>&nbsp;&nbsp;crew member{positions.length !== 1 ? 's' : ''}</span>
              </div>

              </div>
          </div>
        </div>
        {Array.isArray(positions) && positions.length > 0 ? (
            positions.map((position) => (
                <div key={position.id} className={`position ${expanded === position.id ? 'active' : ''}`}>
                <div className="position-header" onClick={() => toggleExpanded(position.id)}>
                    <h3 className='position-name'>{position.title}</h3>
                    <img
                    src={expanded === position.id ? arrowUp : arrowDown}
                    alt={expanded === position.id ? 'Collapse' : 'Expand'}
                    className="arrow"
                    />
                </div>
                {expanded === position.id && (
                    <div className="position-description">
                        <div className="content-wrapper">
                            <div className="text-content">
                                <p>{position.description}</p>
                                <button className="apply-button" onClick={() => smoothScroll('final-section')}>
                                    {t('jobs.apply')}
                                </button>
                            </div>
                            <div className="image-content">
                                <img src={atlantic_jobs_logo} alt="Atlantic Jobs Logo" />
                            </div>
                        </div>
                    </div>
                )}
              </div>
            ))
            ) : (
                <h2 className='no-positions'>{t('jobs.no_positions')}</h2>
            )}
      </section>
    )
}

export default Jobs;
