import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

const CustomCookieConsent = () => {
    const { t } = useTranslation();
    return (
        <CookieConsent
            location="bottom"
            buttonText= "Ok"
            cookieName="defaultCookie"
            style={{
                background: "white",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "normal",
                fontFamily: "Inter, sans-serif",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color:'black'
            }}
            buttonStyle={{
                color: "white",
                background:"#7e4eff",
                fontSize: "14px",
                fontFamily: "Inter, sans-serif",
                alignSelf: "center",
            }}
            expires={750}
            containerClasses="custom-cookie-consent-container"
        >
            {t("cookieConsent")}
        </CookieConsent>
    );
};

export default CustomCookieConsent;