const DecorativeBar = ({ type }) => {
  let startColor = '#7c4bfa';
  let endColor = '#ff4387';

  if (type === 2) {
      [startColor, endColor] = [endColor, startColor];
  }

  return (
      <div style={{
          width: '23%',
          height: '2px',
          color: 'white',
          background: `linear-gradient(to right, ${startColor}, ${endColor})`
      }} />
  );
}

export default DecorativeBar;
