import '../css/ContactForm.css';
import DecorativeBar from './DecorativeBar';
import { useTranslation } from 'react-i18next';
import globe from '../assets/globe.png'
import {useRef, useState} from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
    const {t} = useTranslation();
    const form = useRef();
    const [emailSent, setEmailSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);
        emailjs
            .sendForm(
                "service_w3ayt6n",
                "template_gg4julp",
                form.current,
                "VbAKaioqfBm6WBIdy"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    setEmailSent(true);
                    setLoading(false);
                },
                (error) => {
                    console.log(error.text);
                    setEmailSent(false);
                    setLoading(true);
                }
            );
        e.target.reset();
    };


    return (
      <section id="contact-form" className="contact-form">
          <form className="contact-form"
                onSubmit={sendEmail}
                ref={form}>
              <input id="contact-input" name="from_name" className="contact-form" type="text" placeholder="Your Name..."/>
              <input id="contact-input" name="email" className="contact-form" type="text" placeholder="Your E-mail..."/>
              <textarea id="contact-input" name="message" className="contact-form" placeholder="Your Message..."/>
              <div className="send-message-button">
                  <button>
                      <span className='send-message-span'>Send Message</span>
                  </button>
              </div>
          </form>



      </section>
    )
}

export default ContactForm;
