const realURL = 'https://atlantic-backend.eduproject.eu/';

export const fetchPositions = async (language) => {
    try {
        const response = await fetch(`${realURL}/jobs/list`);
        const data = await response.json();
        return data.results;
    } catch (error) {
        //
    }
};

export const fetchWork = async (language) => {
    try {
        const response = await fetch(`${realURL}/all_products?language=${language}`);
        const data = await response.json();
        return data.data;
    } catch (error) {
        //
    }
};


export const logVisit = async () => {
    try {
        const visitResponse = await fetch(`${realURL}/visits/log-visit/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });
        if (!visitResponse.ok) {
            throw new Error('Could not log visit');
        }
        const visitData = await visitResponse.json();
        return visitData.message;
    } catch (error) {
        return null;
    }
};