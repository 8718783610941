import vrGuy from '../assets/VRguy.png'
import experience_button from "../assets/experience button.png";
import joystick from "../assets/joystic.png";
import hat from "../assets/hat.png";
import house from "../assets/house.png";
import manufacturing from "../assets/manufacturing icon.png";
import shoe from "../assets/shoe.png";
import React from "react";
import Tab from "./Tab";
const ARVRMRSection = () => {

    const tabs = [
        { title: 'Competitive Edge and Innovation',
            content_list:[{content_title: 'Gaming', content_icon:joystick, content_description:'Improve the immersiveness of your world'},
                {content_title: 'Learning', content_icon:hat, content_description:'Create full scale animations and training scenarios'},
                {content_title: 'Manufacturing', content_icon:manufacturing, content_description:'XR-assisted assembly'},
                {content_title: 'Real Estate', content_icon:house, content_description:'Virtual property tours, interior designs'},
                {content_title: 'Retail', content_icon:shoe, content_description:'Try-before-you-buy experience'}]},
        { title: 'Industry-Specific Applications',
            content_list:[{content_title: 'Gaming', content_icon:joystick, content_description:'Improve the immersiveness of your world'},
                {content_title: 'Learning', content_icon:hat, content_description:'Create full scale animations and training scenarios'},
                {content_title: 'Manufacturing', content_icon:manufacturing, content_description:'XR-assisted assembly'},
                {content_title: 'Real Estate', content_icon:house, content_description:'Virtual property tours, interior designs'},
                {content_title: 'Retail', content_icon:shoe, content_description:'Try-before-you-buy experience'}]},
        { title: 'The future is Here and Now',
            content_list:[{content_title: 'Gaming', content_icon:joystick, content_description:'Improve the immersiveness of your world'},
                {content_title: 'Learning', content_icon:hat, content_description:'Create full scale animations and training scenarios'},
                {content_title: 'Manufacturing', content_icon:manufacturing, content_description:'XR-assisted assembly'},
                {content_title: 'Real Estate', content_icon:house, content_description:'Virtual property tours, interior designs'},
                {content_title: 'Retail', content_icon:shoe, content_description:'Try-before-you-buy experience'}]},
    ];

    return (
        <section id="arvr-section" className="arvr-section" style={{position:"relative"}}>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "100%", position: "relative" }}>
                    <Tab defaultTab={1} tabs={tabs} />
                </div>
            </div>



        </section>


    )
}

export default ARVRMRSection;
