import vrGuy from '../assets/VR guy with texts and buttons.png'
import experience_button from "../assets/experience button.png";
import React from "react";
import ARVRMRSection from "./ARVRMRSection";
import development from "../assets/development.png";
import arvr from "../assets/AR&VR.png";
import "../css/ArVr.css"

const ARVRMR = () => {
    return (
        <section id="arvr" className="arvr" style={{ position: 'relative', justifyContent: 'center', display: 'flex'}}>
            <div style={{position:"absolute", marginTop:"-5%", zIndex:1}}>
                <img style={{position:"absolute", marginTop:"-5%", width:"50%", marginLeft:"20%", zIndex:1}} src={arvr}/>
                <div style={{ position: 'relative', justifyContent: 'center', display: 'flex', zIndex: '0', marginTop:"-30%" }}>
                    <img src={vrGuy} style={{maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }} alt="VR Guy" />
                </div>
            </div>


            <div style={{position: 'relative', display:'flex', width: '100%', zIndex: '1', alignItems:'center', flexDirection:'column'}}>
                <span className='development-header' style={{ color: 'white' }}>AR&VR</span>
                <div className="ar-vr-outter-div">
                  <span className="ar-vr-text">
                      AR, VR, and MR each bring distinct advantages to the table. By leveraging all three of them into our business strategy not only positions us at the forefront of technological innovation but also allows us to better connect with our audience, drive engagement, and ultimately, achieve our business objectives in a rapidly evolving digital landscape.  These immersive technologies offer unique opportunities for your businesses to connect with their audience in unprecedented ways, fostering deeper levels of interaction and leaving lasting impressions.
                  </span>
                </div>
                <ARVRMRSection/>
            </div>
        </section>


    )
}

export default ARVRMR;
