import React, {useEffect, useState} from 'react';
import DecorativeBar from "./DecorativeBar";
import background from "../assets/img.png";
import "../css/Tab.css";


const Tab = ({ defaultTab, tabs }) => {
    const [activeTab, setActiveTab] = useState(defaultTab);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleTabClick = (tabIndex) => {
        //setActiveTab(tabIndex);
    };

    return (
        <>
            {isMobile && (
                <div className="mobile-tabs">
                    <div style={{display:"flex", width:"50%", justifyContent:"center"}}>
                        <div>
                            <img src={background} alt="Background"/>
                        </div>
                    </div>
                    <div className="tab-buttons" style={{background:"transparent", display:"flex", justifyContent:"center"}}>
                        {<div>
                            <div style={{width:"100%"}}>
                                <button
                                    key={activeTab}
                                    onClick={() => handleTabClick(activeTab)}
                                    className={activeTab === activeTab ? 'active' : ''}
                                >
                                    {tabs[activeTab].title}
                                </button>
                            </div>

                        </div>}
                    </div>
                    <div className="tab-content" style={{ display: "flex", flexDirection:"column",alignItems: "center", width:"60%" }}>
                            <div className='side-container'>
                                <img src={tabs[activeTab].content_list[0].content_icon}/>
                                <div className='tab-item-title'>
                                    <span>{tabs[activeTab].content_list[0].content_title}</span>
                                </div>
                                <div>
                                    <span>{tabs[activeTab].content_list[0].content_description}</span>
                                </div>
                            </div>
                            <div style={{textAlign:"center"}}>
                                <img src={tabs[activeTab].content_list[2].content_icon}/>
                                <div className='tab-item-title'>
                                    <span>{tabs[activeTab].content_list[2].content_title}</span>
                                </div>
                                <div>
                                    <span>{tabs[activeTab].content_list[2].content_description}</span>
                                </div>
                            </div>
                            <div className='side-container'>
                                <img src={tabs[activeTab].content_list[1].content_icon}/>
                                <div className='tab-item-title'>
                                    <span>{tabs[activeTab].content_list[1].content_title}</span>
                                </div>
                                <div>
                                    <span>{tabs[activeTab].content_list[1].content_description}</span>
                                </div>
                            </div>
                            <div className='side-container'>
                                <img className='tab-item-icon' src={tabs[activeTab].content_list[3].content_icon}/>
                                <div className='tab-item-title'>
                                    <span>{tabs[activeTab].content_list[3].content_title}</span>
                                </div>
                                <div>
                                    <span>{tabs[activeTab].content_list[3].content_description}</span>
                                </div>
                            </div>
                            <div className='side-container'>
                                <img className='tab-item-icon' src={tabs[activeTab].content_list[4].content_icon}/>
                                <div className='tab-item-title'>
                                    <span>{tabs[activeTab].content_list[4].content_title}</span>
                                </div>
                                <div>
                                    <span>{tabs[activeTab].content_list[4].content_description}</span>
                                </div>
                            </div>


                    </div>
                </div>
            )}
            {!isMobile && (
                <div className="tabs">
                    <div className="tab-buttons">
                        {tabs.map((tab, index) => (
                            <div key={index}>
                                <div>
                                    <button
                                        key={index}
                                        onClick={() => handleTabClick(index)}
                                        className={index === activeTab ? 'active' : ''}
                                    >
                                        {tab.title}
                                    </button>
                                </div>
                                <div style={{display:index === activeTab ? 'flex':'none',width:"100%", height:"auto", alignContent:"center", justifyContent:"center"}}>
                                    <DecorativeBar/>
                                </div>

                            </div>
                        ))}
                    </div>
                    <div className="tab-content" style={{ display: "flex", alignItems: "center", width:"60%" }}>
                        <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                            <div>
                                <img src={tabs[activeTab].content_list[0].content_icon}/>
                                <div className='tab-item-title'>
                                    <span>{tabs[activeTab].content_list[0].content_title}</span>
                                </div>
                                <div>
                                    <span>{tabs[activeTab].content_list[0].content_description}</span>
                                </div>
                            </div>
                            <div>
                                <img src={tabs[activeTab].content_list[1].content_icon}/>
                                <div className='tab-item-title'>
                                    <span>{tabs[activeTab].content_list[1].content_title}</span>
                                </div>
                                <div>
                                    <span>{tabs[activeTab].content_list[1].content_description}</span>
                                </div>
                            </div>

                        </div>
                        <div style={{ flex: 1 }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <img src={background} alt="Background" style={{ width: "100%", marginTop:"10%" }} />
                                <div style={{textAlign:"center"}}>
                                    <img src={tabs[activeTab].content_list[2].content_icon}/>
                                    <div className='tab-item-title'>
                                        <span>{tabs[activeTab].content_list[2].content_title}</span>
                                    </div>
                                    <div>
                                        <span>{tabs[activeTab].content_list[2].content_description}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-end", textAlign:"end" }}>
                            <div>
                                <img className='tab-item-icon' src={tabs[activeTab].content_list[3].content_icon}/>
                                <div className='tab-item-title' style={{textAlign:"end"}}>
                                    <span>{tabs[activeTab].content_list[3].content_title}</span>
                                </div>
                                <div>
                                    <span>{tabs[activeTab].content_list[3].content_description}</span>
                                </div>
                            </div>
                            <div>
                                <img className='tab-item-icon' src={tabs[activeTab].content_list[4].content_icon}/>
                                <div className='tab-item-title'>
                                    <span>{tabs[activeTab].content_list[4].content_title}</span>
                                </div>
                                <div>
                                    <span>{tabs[activeTab].content_list[4].content_description}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


        </>
    );
};

export default Tab;
