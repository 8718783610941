import React, { useState } from 'react';
import '../css/MobileMenu.css';
import logo_white from "../assets/logoW.png"; // Import your CSS file for styling

const MobileMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [slideOutAnimation, setSlideOutAnimation] = useState(false);

    const toggleMenu = () => {
            setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setSlideOutAnimation(true);
    };

    const handleAnimationEnd = () => {
        if (menuOpen && slideOutAnimation){
            setMenuOpen(!menuOpen);
            setSlideOutAnimation(false);
        }
    };

    const smoothScroll = (id,modifier) => {
        let  element = document.getElementById(id);
        if (element) {
            toggleMenu();
            window.scrollTo({
                top: element.offsetTop+modifier,
                behavior: 'smooth'
            });
        }
    };

    const menuItems = [
        {title:"About", id:"about", modifier:0},
        {title:"Development", id:"development", modifier:0},
        {title:"AR&VR", id:"arvr", modifier:-550},
        {title:"Jobs", id:"mid-section", modifier:500},
        {title:"Contacts", id:"final-section", modifier:0}
    ]


    function handleTabClick(index) {
        smoothScroll(menuItems[index].id, menuItems[index].modifier);
    }

    return (
        <>
            <div className="menu-icon" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
        <div>
            <ul className={`menu-options ${menuOpen ? 'active' : ''} ${slideOutAnimation ? 'slide-out' : ''}`}
                onAnimationEnd={handleAnimationEnd} >

                <li><div style={{float:"left", height:"auto", width:"90%", marginBottom:"10%"}}><img src={logo_white} alt="Logo" style={{width:"15%"}} /></div>
                <div>
                    <button className="close-icon" onClick={closeMenu}></button>
                </div></li>
                <li><a onClick={() => handleTabClick(0)}>About</a></li>
                <li><a onClick={() => handleTabClick(1)}>Development</a></li>
                <li><a onClick={() => handleTabClick(2)}>AR&VR</a></li>
                <li><a onClick={() => handleTabClick(3)}>Jobs</a></li>
                <li><a onClick={() => handleTabClick(4)}>Contacts</a></li>

            </ul>
        </div>
        </>
    );
};

export default MobileMenu;
