import '../css/Development.css';
import DecorativeBar from './DecorativeBar';
import { useTranslation } from 'react-i18next';
import development from "../assets/development.png";

const Development = () => {
    const {t} = useTranslation();

    return (
      <section id="development" className="development">
          <img className="development-image" style={{position:"absolute", marginTop:"-20%", width:"70%", marginLeft:"10%"}} src={development}/>
        <span className='development-header'>{t("development.development")}</span>
        <DecorativeBar/>
        <div className='development-span-div'>
          <span className='development-span'>{t("development.development_hint")}</span>
        </div>
          {/**<button className="our-projects-button">
                    <span className='our-projects-span'>{t("development.our_projects")}</span>
                </button>      */
          }
      </section>
    )
}

export default Development;
