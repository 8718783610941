import React, {useEffect, useState} from 'react';
import '../css/Navbar.css';
import logo_white from '../assets/logoW.png';
import { useTranslation } from 'react-i18next';
import MobileMenu from "./MobileMenu";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const closeMobileMenu = () => setClick(false);
  const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


  function handleLanguageChange(event) {
    const selectedLanguage = event.target.value;
    console.log("Selected language:", selectedLanguage);
  }


  return (
    <nav className="navbar">
      <div className="navbar-container">
      <a href="/" className="navbar-logo" onClick={closeMobileMenu}>
        <img src={logo_white} alt="Logo" className='navbar-img'/>
      </a>
        <span className="navbar-text">Atlantic Infinity</span>
          <hr className="navbar-divider" />

          {isMobile && (<MobileMenu/>)}


      </div>
    </nav>
  );
};

export default Navbar;
