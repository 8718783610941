import '../css/Contacts.css';
import DecorativeBar from './DecorativeBar';
import { useTranslation } from 'react-i18next';
import globe from '../assets/globe.png'
import ContactForm from "./ContactForm";
import linkedInColored from "../assets/in.png"
import linkedIn from "../assets/inw.png"
import {useState} from "react";

const Contacts = () => {
    const {t} = useTranslation();

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleClick = () => {
        window.open("https://www.linkedin.com/company/atlantic-infinity", "_blank");
    };

    return (
      <section id="contacts" className="contacts">
        <span className='contacts-header'>{t("contacts.contacts")}</span>
          <span className='contacts-description'>{t("contacts.description")}</span>
          <br/>
          <div className="contacts-decorative-bar">
              <DecorativeBar/>
          </div>
          <br/>
          <img src={isHovered ? linkedInColored : linkedIn}
               style={{ width: "50px", cursor:"pointer" }}
               onMouseEnter={handleMouseEnter}
               onMouseLeave={handleMouseLeave}
               alt="LinkedIn"
               onClick={handleClick}
          />
          <ContactForm/>
      </section>
    )
}

export default Contacts;
