import './App.css';
import React,{useEffect} from 'react';
import './i18n';
import MainContent from './components/MainContent';
import { logVisit } from './services/apiService';

function App() {

  useEffect(() => {
    logVisit();
  }, []);

  return (
    <div className='app'>
      <MainContent />
    </div>
  );
}

export default App;
