import React, {useRef, useState} from 'react';
import '../css/MainContent.css';
import LanguageSwitcher from './LanguageSwitcher';
import Navbar from '../components/Navbar';
import Development from './Development';
import ARVRMR from './ARVRMR';
import About from './About';
import Jobs from './Jobs';
import Contacts from './Contacts';
import Footer from "./Footer";
import Starfield from "react-starfield";
import downButton from '../assets/circledown.png';
import experience_button from "../assets/experience button.png";
import ARVRMRSection from "./ARVRMRSection";
import DecorativeBar from "./DecorativeBar";


const MainContent = () => {
    const [activeTab, setActiveTab] = useState(0);

    const smoothScroll = (id,modifier) => {
    let  element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop+modifier,
        behavior: 'smooth'
      });
    }
  };

    const menuItems = [
        {num_id: 0,title:"About", id:"about", modifier:0},
        {num_id: 1,title:"Development", id:"development", modifier:0},
        {num_id: 2,title:"AR&VR", id:"arvr", modifier:-550},
        {num_id: 3,title:"Jobs", id:"mid-section", modifier:500},
        {num_id: 4,title:"Contacts", id:"final-section", modifier:0}
    ]


    function handleTabClick(index) {
        setActiveTab(index)
        smoothScroll(menuItems[index].id, menuItems[index].modifier);
    }

    return (
    <section id="main-content" className="main-content">
        <div className="first-section">
        <Navbar />

            <div className="container">
                <div className='main-title'>
                    <span className='title-span'>Atlantic</span>
                    <span className='title-span'>Infinity</span>
                    <span className='title-hint'>development, technology and innovation</span>
                </div>
                <div>
                        <div className="menu">
                        <div className="tab-buttons" style={{width:"auto"}}>
                            {menuItems.map((tab, index) => (
                                <div key={tab.num_id}>
                                    <div>
                                        <button
                                            key={index}
                                            onClick={() => handleTabClick(index)}
                                            className={index === activeTab ? 'active' : ''}
                                        >
                                            {tab.title}
                                        </button>
                                    </div>
                                    <div style={{display:index === activeTab ? 'flex':'none',width:"100%", height:"auto", alignContent:"center", justifyContent:"center"}}>
                                        <DecorativeBar/>
                                    </div>

                                </div>
                            ))}
            </div>
                        </div>
                    <span className="talk-to-us">
            </span>
                </div>
            </div>
            <About/>
            <Development/>
            <ARVRMR/>
        </div>
        <div id="mid-section" className="mid-section">
            <Jobs/>
        </div>
        <div id="final-section" className="final-section">
            <Contacts/>
            <Footer/>
        </div>


        <Starfield
            starCount={2500}
            starColor={[255, 255, 255]}
            speedFactor={0.1}
            backgroundColor="black"
        />
    </section>
  );
};

export default MainContent;
